
import Vue from "vue";

export default Vue.extend({
  name: "PaymentSuccess",

  data: () => ({
    timer: null as any,
    loading: true as boolean
  }),

  async beforeMount() {
    this.laoding = true;
    try {
      const response = await this.$API
        .auth()
        .activate(this.$route.params.token);
      // await this.$store.dispatch("authentication/login", response);
      await this.$store.dispatch("user/set", response.user);
    } catch (e) {
      await this.$router.push("/auth/email-expired");
    }
    this.loading = false;
  },

  mounted() {
    this.timer = setTimeout(() => {
      this.toMain();
    }, 30000);
  },

  methods: {
    toMain() {
      clearTimeout(this.timer);
      this.$router.push("/auth/login");
    }
  }
});
