
import Vue from "vue";
import { LocaleMessages } from "vue-i18n";
import rules from "@/services/helpers/validation-rules";
import store from "@/store";

export default Vue.extend({
  name: "PasswordExtension",
  data: () => ({
    rules,
    model: {} as any,
    errorMessage: "" as string,
    errorMessages: {} as any,
    loading: false as boolean,
    shownPassword: {
      old: false,
      new: false,
      confirm: false
    } as any
  }),
  computed: {
    user() {
      return store.getters["authentication/credentials"]?.user;
    },
    isSamePassword() {
      return (
        this.model.password === this.model.password_confirmation ||
        this.$t("users.form.validation.not_match")
      );
    }
  },
  methods: {
    samePassword() {
      return (v: string): boolean | string | LocaleMessages =>
        v === this.model.password || `Пароль не совпадает!`;
    },
    async changePassword(): Promise<void> {
      this.loading = true;

      try {
        if (await (this.$refs.form as Vue).validate()) {
          await this.$API.profile().resetPassword(this.user.id, this.model);
          await this.$store.dispatch("alert/showSuccess", "Success");
          await this.$router.push("/auth/login");
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;

          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }

        this.errorMessage = e.message;
      }
      this.loading = false;
    },
    togglePasswordType(key: string) {
      this.shownPassword[key] = !this.shownPassword[key];
    },
    getPasswordType(key: string): string {
      if (this.shownPassword[key]) {
        return "text";
      }
      return "password";
    }
  }
});
