
import Vue from "vue";

export default Vue.extend({
  name: "PaymentSuccess",

  data: () => ({
    timer: null as any
  }),

  mounted() {
    this.$store.dispatch("authentication/logout");
    this.timer = setTimeout(() => {
      this.toMain();
    }, 30000);
  },

  methods: {
    toMain() {
      clearTimeout(this.timer);
      this.$router.push("/auth/login");
    }
  }
});
